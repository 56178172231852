<template>
  <WhatWeDo
    :background-image="this.backgroundImage"
    :background-image-details="'Image Description'"
    :backgroundPosition="this.backgroundPosition"
  >
    <h1>Presentation Skills Training</h1>
      <div class="scrollable-content">
    <p>
      With our foundations in the arts we have years of experience in directing
      and coaching professional artists in live and recorded performance and
      presentations. This has enabled us to pass on these these attributes with
      other businesses.
    </p>
    <p>
      We specialise in supporting to produce excellent communication and dynamic
      presentations for those presenting internal videos, meetings to the public
      at congress, live or recorded events, presentations in the work place to
      fellow colleagues or simply just to gain self confidence and communication
      skills.
    </p>
    <p>
      Working with business teams we can tailor an individual's requirements.
    </p>
    <p>These attributes can involve the following:</p>
    <ul>
      <li>Clarity of Speech, taking into account pace and inflection.</li>
      <li>
        Modulation, supporting the voice, so as to produce an emotionally
        centred, dynamic, authoritative, friendly and direct delivery.
      </li>
      <li>
        Clarity of thought, pausing where necessary and making full sense of
        what is being communicated.
      </li>
      <li>
        Colour of speech, avoiding repetitive rhythm, which can diminish
        interest from an audience.
      </li>
      <li>
        Relaxation and body language, when presenting, avoiding nervous ticks
        and movements, that can distract from the presentation.
      </li>
      <li>
        Passion and belief: getting the message across with passion and
        conviction using focus.
      </li>
      <li>
        Confidence, this can be done by relaxation, through preparation and
        breathing and speech exercises.
      </li>
      <li>Engaging your audience, Eye contact, good humour and warmth</li>
    </ul>
    <p>
      All these presentation skills together with good preparation, controlling
      anxiety and an engaging delivery can be delivered face to face in helping
      business where presentations and communication are a key part of their
      working environment.
    </p>
    <p>
      Click below to hear an example of vocal training technique.
    </p>
<audio controls>
  <source src='@/assets/audio/warfarin.ogg' type="audio/ogg">
  <source src='@/assets/audio/warfarin.mp3' type="audio/mpeg">
  <source src='@/assets/audio/warfarin.wav' type="audio/aac">
Your web browser does not support the audio element.
</audio>
<!-- <div> 
  <button onclick="document.getElementById('player').play()">Play</button> 
  <button onclick="document.getElementById('player').pause()">Pause</button> 
  <button onclick="document.getElementById('player').volume += 0.1">Vol +</button> 
  <button onclick="document.getElementById('player').volume -= 0.1">Vol -</button> 
</div> -->
    </div>
  </WhatWeDo>
</template>
<script>
import WhatWeDo from '@/components/WhatWeDo.vue'
export default {
  components: { WhatWeDo },
  data() {
    return {
      backgroundImage: require('@/assets/images/what-we-do/speaker-training.jpg'),
      backgroundPosition: { x: 85, y: 50}
    }
  },
}
</script>

<template>
  <section id="what-we-do" class="panel">
    <div id="what-we-do-wrapper" @mousemove="mouseMove">
      <div id="what-we-do-background" ref="whatWeDoBackground">
        <LazyImage
          :key="image.id"
          :responsive-images="image.responsiveImages"
          :image-position-x="image.position.x"
          :image-position-y="image.position.y"
          :background-image-tint="image.backgroundImageTint"
          :filename="image.responsiveImages.src"
          :picture-details="image.pictureDetails"
          :alt="image.pictureDetails" />
      </div>
      <div id="what-we-do-content">
        <div id="what-we-do-description" ref="whatWeDoDescription">
          <slot></slot>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { gsap, CSSRulePlugin } from 'gsap/all'
import LazyImage from '@/components/LazyImage.vue'
import VanillaTilt from 'vanilla-tilt'
import { addPaddingToScrollableContent } from '@/assets/scripts/globals'

export default {
  name: 'WhatWeDo',
  props: {
    backgroundImage: {
      type: Object,
      required: true,
      default: () => ({
        src: '',
        srcSet: '',
      }),
    },
    backgroundPosition: {
      type: Object,
      required: false,
      default: () => ({
        x: 50,
        y: 50,
      })
    },
    backgroundImageDetails: {
      type: String,
      default: 'An image showing something that we do',
    },
  },
  components: { LazyImage },
  data() {
    return {
      image: {
        responsiveImages: this.backgroundImage,
        position: this.backgroundPosition,
        backgroundImageTint: 'rgba(0,0,0,0)',
        id: 0,
        pictureDetails: this.backgroundImageDetails,
      },
      // Tilt/move variables
      xPos: 0,
      yPos: 0,
      rotateX: 5,
      rotateY: 5,
      moveStrength: 10,
    }
  },
  beforeUnmount() {
    this.$refs.whatWeDoDescription.vanillaTilt.destroy()
    window.removeEventListener('resize', this.addPaddingToScrollableContent)
  },
  mounted() {
    window.addEventListener('resize', this.addPaddingToScrollableContent)
    addPaddingToScrollableContent()
    VanillaTilt.init(this.$refs.whatWeDoDescription, {
      max: 2,
      speed: 200,
      perspective: 1600,
      glare: false,
      gyroscope: false,
      'full-page-listening': false,
    })
    gsap.registerPlugin(CSSRulePlugin)

    gsap
      .timeline()
      .fromTo(
        '.lazy-image',
        {
          '--backgroundImageTint': 'rgba(0,0,0,0)',
        },
        {
          '--backgroundImageTint': 'rgba(65,23,31,0.85)',
          delay: 2,
          duration: 0.3,
        }
      )
      .fromTo(
        '#what-we-do-content',
        { alpha: 0 },
        { duration: 0.2, alpha: 1, delay: 0 }
      )
      .fromTo(
        '#what-we-do-description > *',
        { alpha: 0, y: '-3vh' },
        { alpha: 1, y: '0vh', stagger: 0.01 }
      )
  },
  methods: {
    hexToRgbA(hex, alpha) {
      var c
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('')
        if (c.length == 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]]
        }
        c = '0x' + c.join('')
        return (
          'rgba(' +
          [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
          ',' +
          alpha +
          ')'
        )
      }
      throw new Error('Bad Hex')
    },
    mouseMove(event) {
      this.xPos = event.clientX / window.innerWidth - 0.5
      this.yPos = event.clientY / window.innerHeight - 0.5

      gsap.to('#what-we-do-background', {
        rotationY: this.rotateX * this.xPos,
        rotationX: this.rotateY * this.yPos,
        x: this.xPos * this.moveStrength,
        y: this.yPos * this.moveStrength,
        ease: 'power1.out',
      })
    },
  },
}
</script>
<style lang="scss">
.lazy-image {
  display: block !important;
  transform: scale(1.02) translateY(1.02);
}

.lazy-image {
  background-color: rgba(0, 0, 0, 0);
}
#what-we-do {
  color: rgb(255, 255, 255);
}
#what-we-do-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}
#what-we-do-background {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: rgb(0, 0, 0) center center / cover; /* enables rotation effect */
  transform-style: preserve-3d;
  transform: translateZ(-100px); /* enables rotation effect */
}
#what-we-do-content {
  color: rgb(255, 255, 255);
  height: 82vh;
  margin: 34px;
}
#what-we-do-description {
  margin: 0 auto;
  transform-style: preserve-3d;
  transform: perspective(1600px);
  max-width: 1440px;
  font-size: 1.6vmin;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  justify-content: center;

  @media #{$mq-tiny} {
    width: 77vw;
    font-size: 4.2vmin;
  }
  @media #{$mq-tiny-small} {
    width: 85vw;
    font-size: 2.3vmin;
  }
  @media #{$mq-small} {
    font-size: 2.1vmin;
  }

  @media #{$mq-medium} {
    font-size: 1.45vmin;
  }

  @media #{$mq-large} {
    font-size: 1.55vmin;
  }
}

#what-we-do-description ul {
  list-style-type: square;
}

#what-we-do-description ul > li {
  padding: 5px;
  margin-left: 35px;
}

#what-we-do-description ol > li {
  padding: 5px;
  margin-left: 35px;
}

#what-we-do-description div > p:nth-child(1) {
  margin-top: 0;
}

.scrollable-content {
    margin-left: 0em;
    margin-right: 0em;
}
</style>